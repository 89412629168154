import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`鎌江一美`}</h1>
    <p>{`鎌江さんは、鎌江さんが思いを寄せるひと、＜まさとさん＞の像を作っています。その 40 センチくらいの像は、たくさんの粘土の粒が表面をびっしりと埋め尽くしていて、毛皮に覆われた小動物のように見えます。目や口が暗くくぼんでいるので少し不気味ではありますが、鎌江さんは、姿形ではなく、好きな人の柔らかで温かい雰囲気と存在感を表そうとしているのかもしれません。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.5625%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUEBv/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAdUuzCL6AF//xAAbEAACAgMBAAAAAAAAAAAAAAACAwABERIjM//aAAgBAQABBQJ/kgsuh1sKK7z/xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BNX//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECEHER/9oACAEBAAY/AmR2uMjtf//EABsQAAEFAQEAAAAAAAAAAAAAAAEAEBEhMUGR/9oACAEBAAE/IfJaKCYM6YJcCunTP//aAAwDAQACAAMAAAAQF9//xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAwEBPxCZ/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EIK//8QAGxABAAEFAQAAAAAAAAAAAAAAAREAECFRkTH/2gAIAQEAAT8QBM+I4oYtAjdinnEk0xA9Pbf/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/6d7db86547dafebc56462d264a1c633f/ec4f2/kazumi-kamae-trim.webp 320w", "/static/6d7db86547dafebc56462d264a1c633f/74ed4/kazumi-kamae-trim.webp 640w", "/static/6d7db86547dafebc56462d264a1c633f/265b4/kazumi-kamae-trim.webp 1280w", "/static/6d7db86547dafebc56462d264a1c633f/ed2e8/kazumi-kamae-trim.webp 1920w", "/static/6d7db86547dafebc56462d264a1c633f/6b78f/kazumi-kamae-trim.webp 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/6d7db86547dafebc56462d264a1c633f/9328e/kazumi-kamae-trim.jpg 320w", "/static/6d7db86547dafebc56462d264a1c633f/f24a5/kazumi-kamae-trim.jpg 640w", "/static/6d7db86547dafebc56462d264a1c633f/36316/kazumi-kamae-trim.jpg 1280w", "/static/6d7db86547dafebc56462d264a1c633f/18346/kazumi-kamae-trim.jpg 1920w", "/static/6d7db86547dafebc56462d264a1c633f/29666/kazumi-kamae-trim.jpg 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/6d7db86547dafebc56462d264a1c633f/36316/kazumi-kamae-trim.jpg",
          "alt": "kazumi kamae trim",
          "title": "kazumi kamae trim",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <Caption before={`作品`} medium={`陶土、300×350×300 (mm)、2012年`} mdxType="Caption">
  《タキシードを着たまさとさん》
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/mari-kashiwagi`} mdxType="LinkButton">応答する人: 柏木麻里</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      